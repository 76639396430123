<template>
<div class="page-box container-box">
    <div v-if="isPageViewable" class="page-panel">
      <div ref="listBox" class="data-list">
        <div ref="listHead" class="data-list__header">
          <el-form class="lz-form--inline" :model="filterForm" label-width="45px">
            <el-row :gutter="16">
              <el-col :sm="10" :md="8" :lg="6" :xl="4">
                <el-form-item :label="$t('酒店：')">
                  <el-select v-model="filterForm.hotelVid" filterable clearable :placeholder="$t('选择考勤酒店')">
                    <el-option v-for="item in hotelList" :key="item.hotelVid" :label="item.hotelName" :value="item.hotelVid"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :sm="10" :md="8" :lg="6" :xl="4">
                <el-form-item :label="$t('日期：')">
                  <el-date-picker v-model="filterForm.date" type="month" :picker-options="datePickerOptions" :clearable="false" :placeholder="$t('选择考勤月份')"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :sm="4" :md="8" :lg="6" :xl="4">
                <el-form-item label-width="0">
                  <el-button type="primary" class="action-button" @click="handleQueryClick">{{$t('查询')}}</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

          <!-- 分隔线视图 -->
          <div class="data-list__separator"></div>
        </div>

        <div class="data-list__body" v-loading="tableLoading">
          <div ref="listAction" class="data-list__action display__flex justify_content__space_between align_items__center">
            <div class="icon-view display__flex align_items__center">
              <el-popover trigger="hover">
                <lz-icon slot="reference" class="icon" type="iconfont" name="lzicon-chat_dot_round" :size="13"></lz-icon>
                <div>{{$t('提示：点击行日期数据可进行编辑')}}</div>
              </el-popover>
              <color-lump-tips :datas="abnormalMarkEnum"></color-lump-tips>
            </div>
            <div>
              <div v-if="isScheduleEditable" class="action-button__icon--basic action-button--primary" @click="handleAddSchduleClick">
                <lz-icon style="margin-right: 6px; line-height: 30px;" type="iconfont" name="lzicon-add"></lz-icon>
                <span class="suffix-text">{{$t('新增排班')}}</span>
              </div>
              <div v-if="isScheduleSubmitable" style="margin-left: 8px;" class="action-button__icon--basic" @click="handleSubmitClick">
                <span class="suffix-text">{{$t('提交到集团')}}</span>
              </div>
            </div>
          </div>
          <div class="data-list__table">
            <el-table ref="table" :empty-text="noDataText" :data="tableDatas" :max-height="tableMaxHeight" :cell-style="getCellStyle" border @cell-mouse-enter="handleTableCellMouseEnter" @cell-mouse-leave="handleTableCellMouseLeave" @cell-click="handleTableCellClick" @select="handleTableSelect" @select-all="handleTableSelectAll">
              <el-table-column type="selection" :selectable="tableColumnSelectable" align="center" fixed width="48"></el-table-column>
              <el-table-column prop="hotelName" :label="$t('考勤酒店')" align="center" fixed min-width="150"></el-table-column>
              <el-table-column :label="$t('员工')" align="center" fixed min-width="100">
                <template slot-scope="scope">
                  <span v-if="!scope.row.remark">{{scope.row.userName}}</span>
                  <el-popover v-else trigger="hover">
                    <div slot="reference">
                      <span style="vertical-align: middle;">{{scope.row.userName}}</span>
                      <lz-icon class="cell-tips__icon" type="iconfont" name="lzicon-warning" :size="14"></lz-icon>
                    </div>
                    <div style="max-width: 300px; white-space: normal;">{{scope.row.remark}}</div>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column prop="jobName" :label="$t('职位')" align="center" min-width="100"></el-table-column>
              <template>
                <el-table-column v-for="index in filterDays" :key="index" :prop="'date_'+index" :label="formatHeaderDateColumn(index)" align="center" min-width="80">
                  <template slot-scope="scope">
                    <div v-if="checkDateColumnData(scope.row, index)">
                      {{getDateAttendanceDesc(scope.row.id, index)}}
                    </div>
                    <div v-else-if="isScheduleEditable && scope.row['editing_'+index]">
                      <lz-icon type="iconfont" name="lzicon-edit" color="#497CF6" :size="16"></lz-icon>
                    </div>
                  </template>
                </el-table-column>
              </template>
              <el-table-column prop="foreShiftCount" :label="$t('早班')" align="center" min-width="80"></el-table-column>
              <el-table-column prop="middleShiftCount" :label="$t('中班')" align="center" min-width="80"></el-table-column>
              <el-table-column prop="nightShiftCount" :label="$t('晚班')" align="center" min-width="80"></el-table-column>
              <el-table-column prop="administrativeShiftCount" :label="$t('行政班')" align="center" min-width="80"></el-table-column>
              <el-table-column prop="restCount" :label="$t('休息')" align="center" min-width="80"></el-table-column>
              <el-table-column prop="lateCount" :label="$t('迟到')" align="center" min-width="80"></el-table-column>
              <el-table-column prop="leaveEarlyCount" :label="$t('早退')" align="center" min-width="80"></el-table-column>
              <el-table-column prop="absentCount" :label="$t('旷工')" align="center" min-width="80"></el-table-column>
              <el-table-column prop="askOffCount" :label="$t('请假')" align="center" min-width="80"></el-table-column>
              <el-table-column prop="oweDays" :label="$t('上月欠休')" align="center" min-width="80"></el-table-column>
              <el-table-column prop="notWorkCount" :label="$t('缺勤数')" align="center" min-width="80"></el-table-column>
              <el-table-column prop="workCount" :label="$t('出勤数')" align="center" min-width="80"></el-table-column>
              <el-table-column :label="$t('上传状态')" align="center" min-width="80">
                <template slot-scope="scope">
                  <lz-tag type="dot" :color="scope.row.submitStatus==1 ? 'green' : 'gray'">{{scope.row.submitStatus == 1 ? $t('已提交') : $t('未提交')}}</lz-tag>
                </template>
              </el-table-column>
              <el-table-column v-if="isScheduleEditable || isScheduleSubmitable" :label="$t('操作')" align="center" fixed="right" width="80">
                <template slot-scope="scope">
                  <span v-if="tableRowRevokeSubmitable(scope.row)" class="table-operate-btn" @click="handleRevokeSubmitClick(scope.$index, scope.row.id)">{{$t('撤销提交')}}</span>
                  <span v-else-if="tableRowDeletable(scope.row)" class="table-operate-btn" @click="handleDeleteClick(scope.$index, scope.row.id)">{{$t('删除')}}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>

    <!-- 撤销提交 -->
    <lz-dialog v-model="showRevokeDialog" name="revoke" width="360px" :title="dialogConfig.title" :confirmButtonText="$t('确认')" :mask-closable="!dialogLoading" loading-enable :loading="dialogLoading" @confirm="handleRevokeDialogConfirm"></lz-dialog>
    <!-- 删除考勤 -->
    <lz-dialog v-model="showDeleteDialog" name="delete-attendance" width="360px" :title="dialogConfig.title" :confirmButtonText="$t('确认')" :mask-closable="!dialogLoading" loading-enable :loading="dialogLoading" @confirm="handleDeleteDialogConfirm"></lz-dialog>
    <!-- 新增/编辑考勤 -->
    <lz-modal v-model="showModal" name="edit-attendance" width="880px" :title="modalConfig.title" :mask-closable="false" loading-enable :loading="modalLoading" footer-hide>
      <div slot="body">
        <transition name="fade">
          <edit-view v-if="showModal" v-model="showModal" :action="modalConfig.action" :id="modalConfig.data.id" :year="modalConfig.data.year" :month="modalConfig.data.month" :day="modalConfig.data.day" :hotelList="hotelList" @loading="(loading) => modalLoading = loading" @success="handleEditSuccessCallback"></edit-view>
        </transition>
      </div>
    </lz-modal>
  </div>
</template>

<script>
import { intl } from "@tci18n/vue2";import { mapState, mapGetters, mapActions } from 'vuex';
import dateHelper from '@/libs/datetime';
import filterTableMixin from '@/common/mixin/filter-table.js';
import listMixin from '../mixins/list';
import serviceAPI from '../api';
import commonAPI from '../../common/api';
import EditView from './edit.vue';
import ColorLumpTips from './color-lump.vue';

/**
 * 考勤管理 - 门店排班
 * @module @/view/attendance
 */
export default {
  name: 'HotelSchedule',
  mixins: [filterTableMixin, listMixin],
  components: {
    EditView,
    ColorLumpTips
  },
  data() {
    return {
      filterForm: {
        hotelVid: '',
        date: ''
      },

      tableLoading: false,
      // 表格勾选项
      tableSelected: [],

      // 考勤有效数据缓存 { id: { day: {}}}
      dailyDatas: null,

      // 是否在考勤周期内
      attendancePeriod: false,

      hotelList: [],
      datePickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },

      dialogLoading: false,
      showRevokeDialog: false,
      showDeleteDialog: false,
      dialogConfig: {
        title: '',
        data: null
      },
      modalLoading: false,
      showModal: false,
      modalConfig: {
        title: '',
        action: '',
        data: null
      }
    };
  },
  computed: {
    ...mapState('attendance', [
    'abnormalMarkEnum']
    ),

    ...mapGetters([
    'pageButtonPermission']
    ),

    ...mapGetters('attendance', [
    'attendanceAbnormalMark',
    'isAttendanceAbnormal']
    ),

    // 是否具有“门店排班查看”权限
    isPageViewPermission() {
      return this.pageButtonPermission('hotel_schedule', 'scheduleViewButton');
    },

    // 是否具有“门店排班编辑”权限
    isScheduleEditPermission() {
      return this.pageButtonPermission('hotel_schedule', 'scheduleEditButton');
    },

    // 是否具有“门店考勤提交”权限
    isScheduleSubmitPermission() {
      return this.pageButtonPermission('hotel_schedule', 'scheduleSubmitButton');
    },

    // 是否“门店排班”可查看（拥有view、edit、submit任一权限）
    isPageViewable() {
      return this.isPageViewPermission || this.isScheduleEditPermission || this.isScheduleSubmitPermission;
    },

    // 是否“门店排班”可编辑（考勤周期内 & 拥有编辑权限）
    isScheduleEditable() {
      return this.attendancePeriod && this.isScheduleEditPermission;
    },

    // 是否“门店排班”可提交（考勤周期内 & 拥有提交权限）
    isScheduleSubmitable() {
      return this.attendancePeriod && this.isScheduleSubmitPermission;
    }
  },
  created() {
    this.initData();
  },
  methods: {
    ...mapActions('attendance', [
    'getAttendanceStateSelectList']
    ),

    initData() {
      if (this.isPageViewable) {
        this.filterForm.date = this.dateNow;
        this.setFilterDays(this.dateNow);

        this.getAttendanceStateSelectList();
        this.getHotelList();
        this.checkHistoryEditable();
        this.getHotelScheduleList();
      }
    },

    /**
     * Table 单元格 style
     */
    getCellStyle({ row, column, rowIndex, columnIndex }) {
      return this.getCellStyleWithRefer(row, column, 1);
    },

    /**
     * Table column 是否可勾选
     * @note 未提交 & 考勤周期内 & 拥有提交权限
     * @param { Object } row row data
     * @returns { Boolean } true/false
     */
    tableColumnSelectable(row, index) {
      return row.submitStatus != 1 && this.attendancePeriod && this.isScheduleSubmitPermission;
    },

    /**
     * Table row 是否可“撤销提交”
     * @note 已提交 & 考勤周期内 & 拥有提交权限
     * @param { Object } data row data
     * @returns { Boolean } true/false
     */
    tableRowRevokeSubmitable(data) {
      return data.submitStatus == 1 && this.attendancePeriod && this.isScheduleSubmitPermission;
    },

    /**
     * Table row 是否可“删除”
     * @note 未提交 & 考勤周期内 & 拥有编辑权限
     * @param { Object } data row data
     * @returns { Boolean } true/false
     */
    tableRowDeletable(data) {
      return data.submitStatus != 1 && this.attendancePeriod && this.isScheduleEditPermission;
    },


    /**
     * “查询”点击事件
     */
    handleQueryClick() {
      this.checkHistoryEditable();
      this.getHotelScheduleList();
    },

    /**
     * “新增排班”点击事件
     */
    handleAddSchduleClick() {
      this.modalConfig.title = intl.$t("新增排班");
      this.modalConfig.action = 1;
      this.modalConfig.data = {
        year: this.filterYear,
        month: this.filterMonth
      };
      this.showModal = true;
    },

    /**
     * “提交”点击事件
     */
    handleSubmitClick() {
      if (!this.tableSelected.length) {
        this.$notice.error(intl.$t("请勾选需要提交的考勤"));
        return;
      }
      this.submitData();
    },

    /**
     * “撤销提交”点击事件
     * @param { Number } index 数据源索引
     * @param { Number } id 数据主键 id
     */
    handleRevokeSubmitClick(index, id) {
      this.dialogConfig.title = intl.$t("确定撤销提交此数据吗？");
      this.dialogConfig.data = {
        index,
        id
      };
      this.showRevokeDialog = true;
    },

    /**
     * “删除”点击事件
     * @param { Number } index 数据源索引
     * @param { Number } id 数据主键 id
     */
    handleDeleteClick(index, id) {
      this.dialogConfig.title = intl.$t("确定移除此数据吗？");
      this.dialogConfig.data = {
        index,
        id
      };
      this.showDeleteDialog = true;
    },


    /** Table 事件 **/
    /**
     * 单元格 hover 进入事件
     */
    handleTableCellMouseEnter(row, column, cell, event) {
      if (/date_\d{1,2}/.test(column.property)) {
        this.$set(row, 'editing_' + Number(column.property.split('_')[1]), true);
      }
    },

    /**
     * 单元格 hover 退出事件
     */
    handleTableCellMouseLeave(row, column, cell, event) {
      if (/date_\d{1,2}/.test(column.property)) {
        this.$set(row, 'editing_' + Number(column.property.split('_')[1]), false);
      }
    },

    /**
     * 单元格点击事件
     * @note 考勤周期内 & 拥有编辑权限 & 天 cell
     */
    handleTableCellClick(row, column, cell, event) {
      if (this.isScheduleEditable && /date_\d{1,2}/.test(column.property)) {
        if (row.submitStatus == 1) {
          this.$notice.error(intl.$t("已提交到集团数据不支持编辑，请撤销提交后编辑！"));
        } else {
          let day = Number(column.property.split('_')[1]);
          this.modalConfig.title = row.userName + intl.$t("-考勤日历");
          this.modalConfig.action = 2;
          this.modalConfig.data = {
            id: row.id,
            year: this.filterYear,
            month: this.filterMonth,
            day
          };
          this.showModal = true;
        }
      }
    },

    /**
     * 表格 select 事件
     * @note 勾选/取消勾选都会触发
     * @param { Array } selection 已选数据项
     * @param { Object } row 选择的数据项
     */
    handleTableSelect(selection, row) {
      this.tableSelected = selection;
    },

    /**
     * 表格 select all 事件
     */
    handleTableSelectAll(selection) {
      this.tableSelected = selection;
    },


    /**
     * 获取酒店列表
     */
    getHotelList() {
      commonAPI.hotelListByName().then((res) => {
        if (res.code == 200 && res.data) {
          this.hotelList = res.data;
        }
      }).catch((error) => {
        console.error('Get hotel list by name error: ', error.message);
      });
    },

    /**
     * 获取门店排班列表
     */
    getHotelScheduleList() {
      let params = {
        hotelVid: this.filterForm.hotelVid,
        workShiftDate: dateHelper.format(this.filterForm.date, 'yyyy-MM')
      };
      this.tableLoading = true;
      serviceAPI.hotelScheduleList(params).then((res) => {
        this.tableLoading = false;
        if (res.code == 200 && res.data) {
          this.dailyDatas = null;
          this.tableDatas = res.data;
        }
        this.setTableMaxHeight(0, 'listBox', 'listHead', 'listAction');
        this.setFilterDays(this.filterForm.date);
      }).catch((error) => {
        this.tableLoading = false;
        this.setFilterDays(this.filterForm.date);
        console.error('Get hotel shcedule list error: ', error.message);
      });
    },

    /**
     * 检查历史考勤是否可编辑（是否已过考勤周期）
     * @note 服务端日期检查
     */
    checkHistoryEditable() {
      let params = { workShiftDate: dateHelper.format(this.filterForm.date, 'yyyy-MM') };
      serviceAPI.checkHistoryEditable(params).then((res) => {
        if (res.code == 200) {
          this.attendancePeriod = res.data;
        }
      }).catch((error) => {
        console.error('Check attendance enable error: ', error.message);
      });
    },


    /**
     * 提交考勤数据
     */
    submitData() {
      let params = { ids: this.tableSelected.map((item) => item.id) };
      this.tableLoading = true;
      serviceAPI.submitData(params).then((res) => {
        this.tableLoading = false;
        if (res.code == 200) {
          this.$notice.success(intl.$t("提交考勤到集团成功"));

          this.processAfterSubmit();
        } else {
          this.$notice.error(res.message);
        }
      }).catch((error) => {
        this.tableLoading = false;
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Submit attendance data error: ', error.message);
      });
    },

    /**
     * 提交后处理逻辑
     */
    processAfterSubmit() {
      this.tableSelected.forEach((item) => {
        item.submitStatus = 1;
      });
      this.$refs['table'].clearSelection();
      this.tableSelected = [];
    },

    /**
     * “删除考勤” dialog 确认
     */
    handleDeleteDialogConfirm() {
      this.deleteData();
    },

    /**
     * 删除考勤数据
     */
    deleteData() {
      let params = { id: this.dialogConfig.data.id };
      this.dialogLoading = true;
      serviceAPI.deleteData(params).then((res) => {
        this.dialogLoading = false;
        if (res.code == 200) {
          this.$notice.success(intl.$t("删除考勤数据成功"));

          this.processAfterDelete();
        } else {
          this.$notice.error(res.message);
        }
      }).catch((error) => {
        this.dialogLoading = false;
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Delete submit error: ', error.message);
      });
    },

    /**
     * 删除数据后处理逻辑
     */
    processAfterDelete() {
      this.showDeleteDialog = false;
      let data = this.tableDatas[this.dialogConfig.data.index];
      let index = this.tableSelected.findIndex((item) => item.id === data.id);
      if (index !== -1) {
        this.$refs['table'].toggleRowSelection(this.tableSelected[index], false);
        this.tableSelected.splice(index, 1);
      }
      this.tableDatas.splice(this.dialogConfig.data.index, 1);
    },


    /**
     * “撤销提交” dialog 确认
     */
    handleRevokeDialogConfirm() {
      this.revokeSubmit();
    },

    /**
     * 撤销提交
     */
    revokeSubmit() {
      let params = { id: this.dialogConfig.data.id };
      this.dialogLoading = true;
      serviceAPI.revokeSubmit(params).then((res) => {
        this.dialogLoading = false;
        if (res.code == 200) {
          this.$notice.success(intl.$t("撤销提交成功"));

          this.processAfterRevokeSubmit();
        } else {
          this.$notice.error(res.message);
        }
      }).catch((error) => {
        this.dialogLoading = false;
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Revoke submit error: ', error.message);
      });
    },

    /**
     * 撤销提交后处理逻辑
     */
    processAfterRevokeSubmit() {
      this.showRevokeDialog = false;
      let data = this.tableDatas[this.dialogConfig.data.index];
      data.submitStatus = 0;
    },


    /**
     * "新增/编辑"排班成功回调
     */
    handleEditSuccessCallback() {
      this.getHotelScheduleList();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/const";

  .container-box {
    padding: 20px;
  }

  .page-panel {
    height: 100%;
    padding: 16px;
    border-radius: 2px;
    background-color: #FFF;
  }

  .data-list {
    height: 100%;
    overflow: hidden;
  }

  .data-list__header {
    .data-list__separator {
      margin-top: 4px;
      height: 1px;
      background-color: $dividing-line-color;
    }
  }

  .data-list__body {
    .data-list__action {
      text-align: right;
      padding-top: 12px;
      padding-bottom: 12px;

      .action-button--primary {
        color: #FFF;
        background-color: $theme-color;
      }

      .icon-view {
        line-height: 1;
      }

      .icon-view .icon {
        color: black;
        cursor: help;
        margin-right: 12px;
      }

      .icon-view .icon:hover {
        color: $theme-color;
      }
    }

    .data-list__table {
      ::v-deep .el-table__fixed,
      ::v-deep .el-table__fixed-right {
        pointer-events: none;
        
        th, td {
          pointer-events: auto;
        }
      }

      ::v-deep .cell {
        line-height: 1.2;
      }

      .cell-tips__icon {
        color: $text-color;
        opacity: 0.6;
        line-height: 1;
        transform: rotate(180deg);
        vertical-align: middle;
        margin-left: 3px;
      }
    }
  }

  //  过渡效果
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.2s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
