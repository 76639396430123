<template>
<div class="calendar-box">
    <table class="calendar-table">
      <thead>
        <th v-for="item in weekDays" :key="item">
          <div class="cell">{{item}}</div>
        </th>
      </thead>
      <tbody>
        <tr v-for="(rowItem, rowIndex) in dayRows" :key="rowIndex">
          <template v-for="(colItem, colIndex) in rowItem">
            <td v-if="colItem.type === 'current'" :key="rowIndex+'_'+colIndex">
              <div class="cell">
                <template v-if="colItem.type === 'current'">
                  <div class="title-view">{{formatCalendarDay(colItem.day)}}</div>
                  <div class="slot-view">
                    <slot :data="colItem"></slot>
                  </div>

                  <template v-if="colItem.day === currentDay">
                    <div class="border-view top"></div>
                    <div class="border-view right"></div>
                    <div class="border-view bottom"></div>
                    <div class="border-view left"></div>
                  </template>
                </template>
              </div>
            </td>
            <td v-else :key="rowIndex+'_'+colIndex" :style="{ 'border-right': isLastBlankColOfFirstRow(rowIndex ,colIndex, rowItem) ? '' : 'none' }" class="blank"></td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { intl } from "@tci18n/vue2";const WEEK_DAYS = [intl.$t("周日"), intl.$t("周一"), intl.$t("周二"), intl.$t("周三"), intl.$t("周四"), intl.$t("周五"), intl.$t("周六")];

import dateHelper from '@/libs/datetime';
import calendarHelper from './calendar';

/**
 * 排班/考勤日历
 * @module @/view/attendance
 */
export default {
  name: 'AttendanceCalendar',
  props: {
    date: {
      type: Date
    },

    // 一周的起始日
    firstDayOfWeek: {
      type: Number,
      default: 7
    },

    // 当前选择 day
    currentDay: {
      type: Number
    }
  },
  computed: {
    // 星期数据
    weekDays() {
      return WEEK_DAYS.slice(this.firstDayOfWeek).concat(WEEK_DAYS.slice(0, this.firstDayOfWeek));
    },

    // 行数据
    dayRows() {
      let firstDayOffset = calendarHelper.firstDayOffsetOfWeek(this.date);
      firstDayOffset = firstDayOffset === 0 ? 7 : firstDayOffset;
      let firstOffset = (7 + firstDayOffset - this.firstDayOfWeek) % 7;
      let prevMonthDays = [];
      let days = dateHelper.daysOfMonth(this.date.getFullYear(), this.date.getMonth());
      for (let i = firstOffset - 1; i >= 0; i--) {
        prevMonthDays.push({
          day: days - i,
          type: 'prev'
        });
      }
      let monthDays = [];
      days = dateHelper.daysOfMonth(this.date.getFullYear(), this.date.getMonth() + 1);
      for (let i = 0; i < days; i++) {
        monthDays.push({
          day: i + 1,
          type: 'current'
        });
      }
      let nextMonthDays = [];
      let lastDayOffet = calendarHelper.lastDayOffsetOfWeek(this.date);
      lastDayOffet = lastDayOffet === 0 ? 7 : lastDayOffet;
      let lastOffet = (7 + lastDayOffet - this.firstDayOfWeek) % 7;
      for (let i = 0; i < 7 - lastOffet - 1; i++) {
        nextMonthDays.push({
          day: i + 1,
          type: 'next'
        });
      }
      return this.splitDay2Rows([...prevMonthDays, ...monthDays, ...nextMonthDays]);
    }
  },
  methods: {
    /**
     * 按 row 拆分 day
     * @param{ Array } day 数据
     * @returns { Array } 拆分后的数据
     */
    splitDay2Rows(days) {
      let datas = [];
      for (let i = 0; i < days.length / 7; i++) {
        let start = i * 7;
        datas.push(days.slice(start, start + 7));
      }
      return datas;
    },


    /**
     * 格式化表头名称
     * @param { Number } day 月份中的第几天
     */
    formatCalendarDay(day) {
      return calendarHelper.formatDateName(day, this.date.getMonth() + 1);
    },

    /**
     * 是否是首行最后一个 blank 元素
     */
    isLastBlankColOfFirstRow(rowIndex, colIndex, rows) {
      if (rowIndex === 0) {
        let length = rows.filter((item) => item.type === 'prev').length;
        if (length !== 0) {
          return length - 1 === colIndex;
        }
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
$_border-color: #F5F6F9;
  $_theme-color: #497CF6;

  .calendar-table {
    table-layout: fixed;
    border-spacing: 0;
    width: 100%;

    th {
      padding: 12px 10px;
      background-color: rgba(222, 227, 238, 0.3);
      text-align: left;
      font-weight: normal;
    }

    th .cell {
      line-height: 1.2;
    }

    td {
      border-right: 1px solid $_border-color;
      border-bottom: 1px solid $_border-color;
    }

    td:first-child {
      border-left: 1px solid $_border-color;
    }

    td.blank {
      border-left: none;
    }

    tr:last-child td.blank {
      border-bottom: none;
    }

    td .cell {
      position: relative;
    }

    td .cell .border-view {
      background-color: transparent;
      z-index: -1;
      position: absolute;
      animation: 1s ease 1s 3 normal highlight-effect;

      &.top {
        height: 1px;
        top: 0;
        right: 0;
        left: 0;
      }

      &.right {
        width: 1px;
        top: 0;
        right: 0;
        bottom: 0;
      }

      &.bottom {
        height: 1px;
        right: 0;
        bottom: 0;
        left: 0;
      }

      &.left {
        width: 1px;
        top: 0;
        bottom: 0;
        left: 0;
      }
    }

    td .title-view {
      padding: 10px;
      line-height: 1.2;
    }
  }

  @keyframes highlight-effect {
    0% {
      background-color: transparent;
    }
    50% {
      z-index: 9;
      background-color: $_theme-color;
    }
    100% {
      background-color: transparent;
    }
  }
</style>
